// 
// user-variables.scss
// Use this to override Bootstrap and Silicon variables
//

// Example of a variable override to change Silicon background color
// Remove the "//" to comment it in and see it in action!
//$body-bg: #12263F;

$primary:                     #ec2d53bb;
$info:		                  #d8362b;